import { useSwaps } from "../hooks/adminGraph"
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Box,
  IconButton
} from '@mui/material';
import { shortAddress, toLocale } from "../hooks/useContract";
import { DWCAddress, transactionUrl } from "../config";
import LaunchIcon from "@mui/icons-material/Launch";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import toast from "react-hot-toast";
import { formatEther } from "viem";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const AllSwaps = () => {
  const { swaps, pagination } = useSwaps()

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="swaps table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Transaction</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>User</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Token In</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Token Out</TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Amount In</TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Amount Out</TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Fee</TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {swaps.map((swap, index) => (
                
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <a 
                    href={transactionUrl + swap.transactionHash} 
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                  >
                    {shortAddress(swap.transactionHash)}
                    <LaunchIcon sx={{ fontSize: 16 }} />
                  </a>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {shortAddress(swap.user)}
                    <CopyToClipboard 
                      text={swap.user}
                      onCopy={() => toast.success('Address copied!')}
                    >
                      <CopyAllIcon sx={{ cursor: 'pointer', fontSize: 16 }} />
                    </CopyToClipboard>
                  </Box>
                </TableCell>
                <TableCell>{swap.tokenIn}</TableCell>
                <TableCell>{swap.tokenOut}</TableCell>
                <TableCell align="right">{toLocale(swap.amountIn)}</TableCell>
                <TableCell align="right">{toLocale(swap.amountOut)}</TableCell>
                <TableCell align="right">{toLocale(swap.feeAmount)}</TableCell>
                <TableCell align="right">{toLocale(swap.price)}</TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <IconButton 
          color="primary"
          onClick={() => pagination.setPage(pagination.currentPage - 1)}
          disabled={!pagination.hasPrevious}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton 
          color="primary"
          onClick={() => pagination.setPage(pagination.currentPage + 1)}
          disabled={!pagination.hasMore}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
