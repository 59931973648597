import React,{useState,useEffect} from 'react'
import { SwapRate } from '../hooks/useContractData'
import { toLocale } from '../hooks/useContract'
import { useGraphData, InFlowOutFlowData } from "../hooks/useGraph";
import { formatUnits } from 'viem';

export default function ReportAnalytics() {

  const { sellPrice, buyPrice, zebBalance, usdtBalance, tradeVol } = SwapRate()

  return (
    <>
      <section
        className="boxsmain"
        style={{ marginTop: 100, marginBottom: 40 }}
      >
        <div className="os_container">
          <div className="titleh2 ">
            <h2 className='text-gradient   bg-gradient-to-br from-yellow-500 via-blue-500 to-blue-400 inline-block text-transparent bg-clip-text  transition-opacity duration-500'>Analytics </h2>
          </div>
          <div className="os_row">
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(zebBalance)}</h2>
                <p>EXBIT Liquidity</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(usdtBalance)}</h2>
                <p>USDT Liquidity</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <p>
                  Buy Price
                  <br />1 EXBIT ~ {toLocale(1 / buyPrice)} USDT
                </p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <p>
                  Sell Price <br />1 EXBIT ~ {toLocale(sellPrice)} USDT
                </p>
              </div>
            </div>
            {/* <div className="col4">
              <div className="cardbox">
                <h2>${tradeVol + swapVolume}</h2>
                <p>
                  <p>24h Trade Volume</p>
                </p>
              </div>
            </div>

            <div className="col4">
              <div className="cardbox">
                <h2>{TotalFlow.dwcInflow} EXBIT</h2>
                <h2>{TotalFlow.usdtInflow} USDT</h2>
                <p>
                  <p>Inflow</p>
                </p>
              </div>
            </div>

            <div className="col4">
              <div className="cardbox">
                <h2>{TotalFlow.dwcOutflow} EXBIT</h2>
                <h2>{TotalFlow.usdtOutflow} USDT</h2>
                <p>
                  <p>Outflow</p>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}