import React from "react";
import "./Footer.css";
import twitterIcon from "../../assets/images/twitter.webp";
import instaIcon from "../../assets/images/insta.svg";
import telegramIcon from "../../assets/images/telegram.webp";
import fbIcon from "../../assets/images/facebook.webp";
import ytIcon from "../../assets/images/youtube.webp";
const Footer = () => {
  return (
    <footer className="footer   flex flex-wrap overflow-hidden">
      <div className="container  ">
        <div className="footer_inner_row">
          <a href="/" aria-current="page" className="footer_logo_wrapper "></a>
        </div>
        <div className="footer_seperator flex flex-row items-center justify-center pb-0 pt-2 relative">
          <div className="footer-seperator-line"></div>
        </div>
        <div className="footer_copyright_wrapper flex flex-wrap justify-center gap-2 sm:justify-between items-center py-3">
          <div className="footer_copyright_text text-center sm:text-left  bg-gradient-to-br from-yellow-500 via-blue-500 to-blue-400 inline-block text-transparent bg-clip-text">
            © 2024 ExBit. All rights reserved
          </div>
          <div className="community_icon_wrapper pb-0 ml-0 sm:ml-9">
            <div className="icon-holder">
              <a href="https://x.com/Exbit_official" target="_blank" className="icon_wrapper inline-block">
                <img
                  className="h-auto w-5 ftr-icon invert"
                  src={twitterIcon}
                  alt="Twitter"
                />
              </a>
            </div>
            <div className="icon-holder">
              <a href="https://www.youtube.com/@Exbit_official" target="_blank" className="icon_wrapper inline-block">
                <img
                  className="h-auto w-6 ftr-icon invert"
                  src={ytIcon}
                  alt="Medium"
                />
              </a>
            </div>
            {/* <div className="icon-holder">
              <a href="" target="_blank" className="icon_wrapper inline-block">
                <img
                  className="h-auto w-6 ftr-icon invert"
                  src={fbIcon}
                  alt="Facebook"
                />
              </a>
            </div> */}
            <div className="icon-holder">
              <a href="https://www.instagram.com/exbit_ai/" target="_blank" className="icon_wrapper inline-block">
                <img
                  className="h-auto w-6 ftr-icon"
                  src={instaIcon}
                  alt="Instagram"
                />
              </a>
            </div>

            <div className="icon-holder">
              <a href="https://t.me/EXBitofficial24" target="_blank" className="icon_wrapper inline-block">
                <img
                  className="h-auto w-6 ftr-icon invert"
                  src={telegramIcon}
                  alt="Telegram"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
