import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../src/pages/Layout'
import Home from '../src/pages/Home';
import loader from "../src/assets/images/exbitlogo.png"
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { bsc, polygon, polygonMumbai } from 'wagmi/chains';
import { publicProvider } from "wagmi/providers/public"
import { RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import Adminpanel from './components/Adminpanel';
import "../src/assets/CSS/style.css"
import AdminReport from './components/Adminreport';
import { Toaster } from 'react-hot-toast'


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [publicProvider(), publicProvider()]
);
const { connectors } = getDefaultWallets({
  appName: 'Defi Swap',
  projectId: '74ff0c8063613ea7db447832e6049d4d',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
  }, []);
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider coolMode chains={chains} theme={darkTheme()} showRecentTransactions={true}>
        <BrowserRouter>
        {loading ? (
     
     <div className="loader-container">
       <span className="loader">
         <img src={loader} ></img>
       </span>
     </div>
   ) : (
            // Render the Routes when loading is false
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='hjsd764dalwjifn' element={<Adminpanel />} />
                <Route path='dfklf0928mlsl' element={<AdminReport />} />
              </Route>
            </Routes>
          )}
          <Toaster position='top-right' />
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
