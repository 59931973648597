import { useEffect, useState } from "react";
import { shortAddress } from "../hooks/useContract";
import toast from "react-hot-toast";
import { 
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box
} from "@mui/material";
import { useAccount } from "wagmi";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AnalyticsData } from "../hooks/useContractData";
import { apiSecret, transactionUrl } from "../config";
import { useBlackList } from "../hooks/adminGraph";
import LaunchIcon from '@mui/icons-material/Launch';
import BlockIcon from '@mui/icons-material/Block';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CheckBoxOutlineBlankRounded, CheckCircleOutlineRounded } from "@mui/icons-material";

export default function BlackListTable() {
    const blackList = useBlackList()

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
                <Table aria-label="blacklist table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Transaction</TableCell>
                            <TableCell>Account</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blackList.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <a
                                        href={transactionUrl + item.transactionHash}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                    >
                                        {shortAddress(item.transactionHash)}
                                        <LaunchIcon sx={{ fontSize: 16 }} />
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {shortAddress(item.account)}
                                        <CopyToClipboard
                                            text={item.account}
                                            onCopy={() => toast.success('Address copied!')}
                                        >
                                            <CopyAllIcon sx={{ cursor: 'pointer', fontSize: 16 }} />
                                        </CopyToClipboard>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {item.isBlacklisted ? 
                                        <BlockIcon color="error" /> : 
                                        <CheckCircleOutlineRounded color="success" />
                                    }
                                </TableCell>
                                <TableCell>
                                    {new Date(parseInt(item.blockTimestamp) * 1000).toLocaleString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}