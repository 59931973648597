import { usePublicClient, useWalletClient } from 'wagmi'

export const useAddToken = () => {
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient()

  const addToken = async ({ address, symbol, decimals, image }) => {
    try {
      if (!walletClient) {
        throw new Error('Wallet not connected')
      }

      const wasAdded = await walletClient.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals,
            image
          },
        },
      })

      if (wasAdded) {
        return true
      }
      return false
    } catch (error) {
      console.error('Error adding token to wallet:', error)
      return false
    }
  }

  return { addToken }
}