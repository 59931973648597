import {useEffect, useMemo, useState} from "react";
import { useGraphqlQuery } from "./useGraphqlQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { formatEther } from "ethers/lib/utils";
import { DWCAddress } from "../config";

export const useSwaps = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(parseInt(queryParams.get('page') || '1', 10));
  const [pageSize, setPageSize] = useState(parseInt(queryParams.get('pageSize') || '5', 10));

  // Update URL when page/pageSize changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    params.set('pageSize', pageSize.toString());
    navigate(`${location.pathname}?${params}`, { replace: true });
  }, [page, pageSize, location.pathname, navigate]);

  const skip = (page - 1) * pageSize;
  
  const query = `{
    swapExecuteds(
      orderBy: blockTimestamp
      orderDirection: desc
      skip: ${skip}
      first: ${pageSize}
    ) {
      tokenIn
      tokenOut
      user
      price
      amountIn
      amountOut
      feeAmount
      transactionHash
    }
  }`

  const { data } = useGraphqlQuery(query)

  return useMemo(() => {
    const swaps = data?.swapExecuteds || [];
    const hasMore = swaps.length === pageSize;

    return {
      swaps: swaps.map(swap => ({
        ...swap,
        amountIn: formatEther(swap.amountIn),
        amountOut: formatEther(swap.amountOut),
        price: formatEther(swap.price),
        feeAmount: formatEther(swap.feeAmount),
        tokenIn: swap.tokenIn.toLowerCase() === DWCAddress.toLowerCase() ? "ExBIT" : "USDT",
        tokenOut: swap.tokenOut.toLowerCase() === DWCAddress.toLowerCase() ? "ExBIT" : "USDT",
      })),
      pagination: {
        currentPage: page,
        pageSize,
        hasMore,
        hasPrevious: page > 1,
        totalPages: hasMore ? page + 1 : page,
        setPage,
        setPageSize
      }
    }
  }, [data, page, pageSize])
}


export const useBlackList = () => {
  const query = `{
    blacklistUpdateds(orderBy: blockTimestamp, orderDirection: desc) {
      account
      isBlacklisted
      transactionHash
      blockTimestamp
    }
  }`

  const { data } = useGraphqlQuery(query)

  return useMemo(() => {
    return data?.blacklistUpdateds || []
  }, [data])
}

export const usePriceUpdate = () => {
  const query = `{
    pricesUpdateds(orderBy: blockTimestamp, orderDirection: desc) {
      oldBuyPrice
      oldSellPrice
      newSellPrice
      newBuyPrice
      updatedBy
      transactionHash
      blockTimestamp
    }
  }`

  const { data } = useGraphqlQuery(query)

  return useMemo(() => {
    return data?.pricesUpdateds || []
  }, [data])
}

export const useSwapLimitUpdates = () => {
  const query = `{
    swapLimitsUpdateds(orderBy: blockTimestamp, orderDirection: desc) {
      maxDailyAmount
      transactionHash
      blockTimestamp
    }
  }`

  const { data } = useGraphqlQuery(query)

  return useMemo(() => {
    return data?.swapLimitsUpdateds || []
  }, [data])
}

export const useWithdrawals = () => {
  const query = `{
    withdrawals {
      id
      amount
      token
      transactionHash
    }
  }`

  const { data } = useGraphqlQuery(query)

  return useMemo(() => {
    return data?.withdrawals || []
  }, [data])
}
