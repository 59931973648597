import React, { useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import Swap_page from "./Swap";
import { useAddToken } from "../hooks/addToken";
import { appURL, DWCAddress } from "../config";
import { useAccount, useSwitchNetwork } from "wagmi";


const texts = ["Seamless", "Consistent",   "Integrated"];

const notify = () => toast.success("Copied Successfully!");

const Banner = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const { addToken } = useAddToken()

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFadingOut(true);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsFadingOut(false);
      }, 500);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const addExbit = async () => {
    const success = await addToken({
      address: DWCAddress,
      symbol: 'EXBIT',
      decimals: 18,
      image: `${appURL}exbitlogo.png` 
    })
    
    if (success) {
      toast.success('EXBIT Token added to wallet')
    } else {
      toast.error('Failed to add token')
    }
  }

  const { switchNetwork } = useSwitchNetwork()
  const { address } = useAccount()
  const addMainnet = async () => {
    if (!address) {
      toast.error('Please connect your wallet')
      return
    }
    switchNetwork(56)
  }

  return (
    <>
      <section className="relative mb-30 pt-16 sm:pt-28 md:pt-20 banner-sec ">

        <div className="container mx-auto">
          <div className="flex flex-wrap items-center justify-center gap-y-14 sm:gap-y-20">
            <div className="w-full text-center mx-auto">
              <div className="infobox justify-center flex flex-col items-center max-w-5xl mx-auto">
                <h1 className="text-4xl md:text-5xl font-bold mb-2 title-fnt ">
                  <span
                    className={`text-gradient   bg-gradient-to-br from-yellow-500 via-blue-500 to-blue-400 inline-block text-transparent bg-clip-text  transition-opacity duration-500 ${
                      isFadingOut ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    {texts[currentTextIndex]}
                  </span>{" "}
                   Token Swapping, Unlimited Potential

                </h1>
                <p className="mb-0 mt-3 max-w-3xl font-thin leading-relaxed text-justify sm:text-center">
                Experience the power of Static Swap – your gateway to secure, efficient, and lightning-fast token swapping. Unlock endless possibilities with advanced swap features built on Binance Smart Chain.
                </p>
                <div className="flex flex-wrap gap-4 py-2 mt-5 justify-center items-center">
                  <div className="bnrbtn transition-all duration-300 relative ease-in-out transform hover:scale-105">
                    <button className="w-32 flex justify-center px-2 py-3 relative  border-white rounded-lg bg-gradient-to-b from-blue-500 to-custom-dark-end hover:from-blue-600  hover:to-custom-dark-end " onClick={addExbit}>
                     Add Exbit
                    </button>
                  </div>
                  {/* <div className="bnrbtn transition-all duration-300 relative ease-in-out transform hover:scale-105">
                    <button className="w-32 flex justify-center px-2 py-3 relative border-white rounded-lg bg-gradient-to-b from-blue-500 to-custom-dark-end hover:from-blue-600  hover:to-custom-dark-end" onClick={addMainnet}>
                     Add Mainnet
                    </button>
                  </div> */}
                  {/* <div className="bnrbtn transition-all duration-300 relative ease-in-out transform hover:scale-105">
                    <button className="w-32 flex justify-center px-2 py-3 relative border-white rounded-lg bg-gradient-to-b from-blue-500 to-custom-dark-end  hover:from-blue-600  hover:to-custom-dark-end">
                     Buy Exbit
                    </button>
                  </div> */}
             
                </div>
              </div>
            
            </div>
            <div>
            <Swap_page />
            </div>
           
           
          </div>
        
        </div>
        
      </section>
     
    </>
  );
};

export default Banner;
