import { useWithdrawals } from "../hooks/adminGraph"
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Paper,
    Box
} from '@mui/material';
import { shortAddress, toLocale } from "../hooks/useContract";
import { DWCAddress, transactionUrl } from "../config";
import LaunchIcon from "@mui/icons-material/Launch";
import { formatEther } from "viem";

export const Withdrawals = () => {
    const withdrawals = useWithdrawals()
    
    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
                <Table aria-label="withdrawals table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Transaction</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Token</TableCell>
                            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {withdrawals.map((withdrawal, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <a
                                        href={transactionUrl + withdrawal.transactionHash}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                    >
                                        {shortAddress(withdrawal.transactionHash)}
                                        <LaunchIcon sx={{ fontSize: 16 }} />
                                    </a>
                                </TableCell>
                                <TableCell>{withdrawal.token.toLowerCase() === DWCAddress.toLowerCase() ? "ExBIT" : "USDT"}</TableCell>
                                <TableCell align="right">
                                    {toLocale(formatEther(withdrawal.amount))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}