import { useWaitForTransaction } from "wagmi"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import SpinnerLoader from "./Spinner"

// CSS-in-JS styles
const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  spinnerWrapper: {
    position: 'relative',
    width: '100px',
    height: '100px',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50px',
    height: '50px',
    border: '3px solid rgba(0, 123, 255, 0.3)',
    borderTop: '3px solid #007bff',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingText: {
    marginTop: '20px',
    color: '#007bff',
    fontSize: '14px',
    fontWeight: '500',
  },
  txLink: {
    marginTop: '10px',
    color: '#6c757d',
    fontSize: '12px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  successMessage: {
    display: 'flex',
    alignItems: 'center',
    color: '#28a745',
    fontSize: '16px',
    fontWeight: '500',
  },
  errorMessage: {
    display: 'flex', 
    alignItems: 'center',
    color: '#dc3545',
    fontSize: '16px',
    fontWeight: '500',
  },
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) rotate(360deg)', 
    },
  },
}

export const CustomLoader = ({ hash, label }) => {
  const [visible, setVisible] = useState(false);
  const { isLoading, isSuccess, isError } = useWaitForTransaction({
    hash,
    onSuccess: () => {
      toast.success("Transaction successful!")
      // Hide after 3 seconds on success
      setTimeout(() => setVisible(false), 3000)
    },
    onError: (error) => {
      toast.error(`Transaction failed: ${error.message}`)
      // Hide after 3 seconds on error
      setTimeout(() => setVisible(false), 3000)
    }
  })

  // Reset visibility when hash changes
  useEffect(() => {
    if (hash) {
      setVisible(true)
    }
  }, [hash])

  if (!visible) return null;

  return (
    <>
      <style>
        {`
          @keyframes customLoaderSpin {
            0% { transform: translate(-50%, -50%) rotate(0deg); }
            100% { transform: translate(-50%, -50%) rotate(360deg); }
          }
        `}
      </style>
      <div style={styles.loaderContainer}>
        {isLoading && (
            <SpinnerLoader />
        )}
        
        {isSuccess && (
          <div style={styles.successMessage}>
            <svg 
              style={styles.icon}
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
            </svg>
            <p>{label}</p>
          </div>
        )}

        {isError && (
          <div style={styles.errorMessage}>
            <svg
              style={styles.icon}
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
            <p>Transaction Failed</p>
          </div>
        )}
      </div>
    </>
  )
}