import React, { useState, useEffect } from "react";
import { AdminGraph, AllSwapsInRangeSheet, useSwaps } from "../hooks/adminGraph";
import "../assets/CSS/Admin.css"
import { shortAddress, toLocale } from "../hooks/useContract";
import { DWCAddress, transactionUrl } from "../config";
import { formatUnits } from "viem";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";

import { Pagination } from "@mui/material";
import BlackListTable from "./BlacklistTable";
import ReportAnalytics from "./ReportAnalytics";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import { AnalyticsData } from "../hooks/useContractData";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, MenuItem, Menu, Button, useMediaQuery, useTheme, SwipeableDrawer, List, ListItem, ListItemText, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { AllSwaps } from "./AllSwaps";
import { PriceUpdateList } from "./PriceUpdateList";
import { SwapLimitUpdates } from "./SwapLimitUpdates";
import { Withdrawals } from "./Withdrawals";

dayjs.extend(utc);

const AdminReport = () => {
  const { address } = useAccount();
  const { isHandler } = AnalyticsData();
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    "All Swaps",
    "BlackList Updates",
    "Price Updates", 
    "Swap Limit Updates",
    "Withdrawals"
  ];

  const swaps = useSwaps();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (index) => {
    setSelectedTab(index);
    setAnchorEl(null);
    setDrawerOpen(false);
  };

  if (!address) return <>
    <section className="mainbox_ad adminform banner-sec">
      <div className="os_container_admin mb-20 mt-0">
        <div className="boxmain_ad">
          <h2>Connect Wallet</h2>
        </div>
      </div>
    </section>
  </>

  if (!isHandler) return <>
    <section className="mainbox_ad adminform banner-sec">
      <div className="os_container_admin mb-20 mt-0">
        <div className="boxmain_ad">
          <h2>Not Admin</h2>
        </div>
      </div>
    </section>
  </>

  return (
    <>
      <section
        className="boxsmain adminReportTable banner-sec"
        style={{ marginTop: 40, marginBottom: 40 }}
      >
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {isMobile ? (
            <>
              <IconButton 
                edge="start" 
                color="inherit" 
                onClick={() => setDrawerOpen(true)}
                sx={{ mb: 2, ml: 2 }}
              >
                <MenuIcon />
              </IconButton>
              
              <SwipeableDrawer
                anchor={"bottom"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
              >
                <List>
                  {menuItems.map((item, index) => (
                    <ListItem 
                      button 
                      key={item}
                      selected={selectedTab === index}
                      onClick={() => handleMenuClose(index)}
                    >
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </SwipeableDrawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Button
                variant="contained"
                onClick={handleMenuClick}
                sx={{ minWidth: 200 }}
              >
                {menuItems[selectedTab]}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={item}
                    selected={selectedTab === index}
                    onClick={() => handleMenuClose(index)}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          <Box sx={{ p: 3 }}>
            {selectedTab === 0 && (
              <AllSwaps />
            )}
            {selectedTab === 1 && (
              <BlackListTable />
            )}
            {selectedTab === 2 && (
              <PriceUpdateList />
            )}
            {selectedTab === 3 && (
              <SwapLimitUpdates />
            )}
            {selectedTab === 4 && (
              <Withdrawals />
            )}
          </Box>
        </Box>
      </section>
      <ReportAnalytics />

    </>
  );
};

export default AdminReport;
