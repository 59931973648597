import React, { useState } from "react";
import "../assets/CSS/Admin.css";
import FormField from "./AdminPanelComponent";
import ReportAnalytics from "./ReportAnalytics";
import { useAccount } from "wagmi";
import { AdminFunction, AnalyticsData } from "../hooks/useContractData";
import toast from "react-hot-toast";
import { DWCAddress, USDTAddress, apiSecret } from "../config";
import { SwapRate } from "../hooks/useContractData";

const Adminpanel = () => {
  const [prices, setPrices] = useState({ sell: 0, buy: 0 });
  const { address } = useAccount();
  
  const handlePriceChange = (type, value) => {
    setPrices({ ...prices, [type]: value });
  };
  const { feeAddress, perSwapLimit, isHandler } = AnalyticsData();

  const { zebBalance, usdtBalance, buyPrice, sellPrice, adminFeeRate: adminFees } = SwapRate();

  async function isEthereumAddress(address) {
    const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return ethereumAddressRegex.test(address);
  }

  const addToBlacklist = async (address) => {
    setBlacklist(address, true)
  };

  const removeFromBlacklist = async (address) => {
    setBlacklist(address, false)
  };

  const {
    setUsdtSwapRate,
    AddLiquidity,
    EmergencyWithdraw,
    EmergencyWithdrawAll,
    ChangeAdminFee,
    ChangeFeeAddress,
    ChangePerSwapMaxLimit,
    setZebSwapRate,
    setBlacklist
  } = AdminFunction();

  // if (!validAdmin) {
  //   return <section className="mainbox_ad adminform">
  //     <div className="os_container_admin mb-20 mt-20">
  //       <div className="boxmain_ad">
  //         <h2 >Not Admin</h2>
  //       </div>
  //     </div>
  //   </section>;
  // }

  if (!address) return <>
    <section className="mainbox_ad adminform banner-sec">
      <div className="os_container_admin mb-20 mt-0">
        <div className="boxmain_ad">
          <h2>Connect Wallet</h2>
        </div>
      </div>
    </section>
  </>

if (!isHandler) return <>
<section className="mainbox_ad adminform banner-sec">
  <div className="os_container_admin mb-20 mt-0">
    <div className="boxmain_ad">
      <h2>Not Admin</h2>
        </div>
      </div>
    </section>
  </>

  return (
    <>
      <section className="mainbox_ad adminform banner-sec">
        <div className="os_container_admin mb-20 mt-0">
          <div className="boxmain_ad">
            <div className="boxinner_ad">
              <FormField
                label="Blacklist Address :"
                placeholder="Enter Blacklist Address"
                onSubmit={addToBlacklist}
              />
              <FormField
                label="Whitelist Address :"
                placeholder="Enter WhiteList Address"
                onSubmit={removeFromBlacklist}
              />

              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>AddLiquidity</strong>
                </div>
                <div className="inputbox_ad">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      AddLiquidity(e.target.token.value, e.target.amount.value);
                    }}
                  >
                    <input
                      classname="inputswap"
                      type="number"
                      name="amount"
                      autoComplete="off"
                      placeholder="Enter Amount"
                    />

                    <select
                      className="inputswapbtn outline-none"
                      name="token"
                      id=""
                    >
                      <option value={DWCAddress}>EXBIT</option>
                      <option value={USDTAddress}>USDT</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Emergency Withdraw</strong>
                </div>
                <div className="inputbox_ad">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      EmergencyWithdraw(
                        e.target.token.value,
                        e.target.amount.value
                      );
                    }}
                  >
                    <input
                      classname="inputswap"
                      type="number"
                      name="amount"
                      autoComplete="off"
                      placeholder="Enter Amount"
                    />

                    <select
                      className="inputswapbtn outline-none"
                      name="token"
                      id=""
                    >
                      <option value={DWCAddress}>EXBIT</option>
                      <option value={USDTAddress}>USDT</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Emergency (ALL)</strong>
                </div>
                <div className="inputbox_ad">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      EmergencyWithdrawAll(e.target.token.value);
                    }}
                  >
                    <select
                      className="Emergencydrop outline-none"
                      name="token"
                      id=""
                    >
                      <option value={DWCAddress}>EXBIT</option>
                      <option value={USDTAddress}>USDT</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <FormField
                label="Admin Fee (%): MIN (0.001%) :"
                placeholder={adminFees * 100}
                onSubmit={ChangeAdminFee}
              />
              <FormField
                label="Change Fee Address :"
                placeholder={feeAddress}
                onSubmit={ChangeFeeAddress}
              />
              <FormField
                label="Max Swap Limit :"
                placeholder={perSwapLimit}
                onSubmit={ChangePerSwapMaxLimit}
              />
              <FormField
                label={`Sell Price ( 1 EXBIT = ${sellPrice} USDT ) :`}
                placeholder="Enter Amount"
                onSubmit={(val) => setZebSwapRate(val)}
                onChange={(e) => handlePriceChange("sell", e.target.value)}
              />
              <FormField
                label={`BUY Price ( 1 EXBIT = ${1/buyPrice} USDT ) :`}
                placeholder="Enter Amount"
                onSubmit={(val) => setUsdtSwapRate(val)}
                onChange={(e) => handlePriceChange("buy", e.target.value)}
              />
            </div>
          </div>
        </div>
        <ReportAnalytics />
      </section>
    </>
  );
};

export default Adminpanel;
