import axios from "axios"
import { useCallback, useEffect, useMemo, useState } from "react"
import { graphQlUrl } from "../config"

export const useGraphqlQuery = (query) => {
  const [data, setData] = useState(null)

  const fetchData = useCallback(async () => {
    const response = await axios.post(graphQlUrl, { query })
    setData(response.data)
  }, [query])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return useMemo(() => ({ data: data?.data }), [data])
}
