import { usePriceUpdate } from "../hooks/adminGraph"
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Box
} from '@mui/material';
import { shortAddress, toLocale } from "../hooks/useContract";
import { transactionUrl } from "../config";
import LaunchIcon from "@mui/icons-material/Launch";
import { formatEther } from "viem";

export const PriceUpdateList = () => {
    const priceUpdates = usePriceUpdate()
    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
                <Table aria-label="price updates table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Transaction</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Updated By</TableCell>
                            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Buy Price</TableCell>
                            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>Sell Price</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Updated At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {priceUpdates.map((update, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <a
                                        href={transactionUrl + update.transactionHash}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                    >
                                        {shortAddress(update.transactionHash)}
                                        <LaunchIcon sx={{ fontSize: 16 }} />
                                    </a>
                                </TableCell>
                                <TableCell>{shortAddress(update.updatedBy)}</TableCell>
                                <TableCell align="right">
                                    <span style={{ color: '#4caf50' }}>{toLocale(formatEther(update.newBuyPrice))}</span>
                                </TableCell>
                                <TableCell align="right">
                                    <span style={{ color: '#f44336' }}>{toLocale(formatEther(update.newSellPrice))}</span>
                                </TableCell>
                                <TableCell>
                                    {new Date(parseInt(update.blockTimestamp) * 1000).toLocaleString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}